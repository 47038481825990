<template>
    <div class="ModernWelcomePage">
        <div class="ModernWelcomePage--Content">
            <svg width="140" height="21" viewBox="0 0 140 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3517_780)">
                <path d="M31.4918 13.0446C31.4918 11.2253 30.7245 7.74854 26.4655 7.74854C23.0507 7.74854 21.0171 10.0125 21.0171 14.0957C21.0171 18.5428 23.1658 20.5238 26.6957 20.5238C28.9979 20.5238 30.3024 19.4726 30.7628 19.0684C30.993 18.8662 31.1849 18.5024 31.1849 18.0981C31.1849 17.4108 30.6477 16.8448 29.9954 16.8448C29.6501 16.8448 29.4583 16.9661 29.1897 17.1278C28.5758 17.5725 27.8084 17.9364 26.6957 17.9364C25.0458 17.9364 23.7413 17.047 23.7413 15.1468L29.9571 15.1468C31.4918 15.1873 31.4918 14.0149 31.4918 13.0446ZM23.7797 12.802C23.8948 11.1041 24.9691 10.2146 26.4271 10.2146C28.077 10.2146 28.7676 11.5083 28.7676 12.802L23.7797 12.802Z" fill="#8134FF"/>
                <path d="M42.6961 7.78882C40.5474 7.78882 39.2812 8.67823 38.5522 9.48679C38.322 9.76979 38.2069 10.0528 38.2069 10.3762C38.2069 11.0635 38.744 11.6295 39.3963 11.6295C39.7033 11.6295 39.8951 11.5486 40.1637 11.3465C40.7776 10.8613 41.5834 10.3358 42.6961 10.3358C44.1541 10.3358 44.9982 11.1443 44.9982 12.3167V12.8019L41.6985 12.8019C39.5114 12.8019 37.6313 14.0551 37.6313 16.5617C37.6313 18.9469 39.2812 20.564 41.7368 20.564C43.2332 20.564 44.3459 20.1598 45.0366 19.0278C45.0366 19.7959 45.6505 20.4428 46.3795 20.4428C47.1085 20.4428 47.7224 19.7959 47.7224 19.0278L47.7224 12.1955C47.684 9.40594 45.8039 7.78882 42.6961 7.78882ZM44.9982 15.7531C44.9982 17.249 43.7704 18.1384 42.4275 18.1384C41.0078 18.1384 40.3555 17.532 40.3555 16.5213C40.3555 15.551 40.9695 15.0254 42.0822 15.0254L45.0366 15.0254V15.7531H44.9982Z" fill="#8134FF"/>
                <path d="M55.5111 7.78882C54.4368 7.78882 53.2857 8.43567 52.7869 9.48679V9.36551C52.7869 8.55695 52.173 7.95053 51.444 7.95053C50.6766 7.95053 50.1011 8.59738 50.1011 9.36551L50.1011 18.9874C50.1011 19.7959 50.715 20.4023 51.444 20.4023C52.2114 20.4023 52.7869 19.7555 52.7869 18.9874L52.7869 13.4487C52.7869 11.6699 53.7845 10.4975 55.5111 10.4975C56.2018 10.4975 56.7773 9.89107 56.7773 9.16337C56.7773 8.39524 56.2018 7.78882 55.5111 7.78882Z" fill="#8134FF"/>
                <path d="M67.5207 13.0446C67.5207 11.2253 66.7533 7.74854 62.4943 7.74854C59.0795 7.74854 57.0459 10.0125 57.0459 14.0957C57.0459 18.5428 59.1946 20.5238 62.7245 20.5238C65.0267 20.5238 66.3312 19.4726 66.7916 19.0684C67.0219 18.8662 67.2137 18.5024 67.2137 18.0981C67.2137 17.4108 66.6765 16.8448 66.0243 16.8448C65.6789 16.8448 65.4871 16.9661 65.2185 17.1278C64.6046 17.5725 63.8372 17.9364 62.7245 17.9364C61.0747 17.9364 59.7701 17.047 59.7701 15.1468L65.9859 15.1468C67.5207 15.1873 67.5207 14.0149 67.5207 13.0446ZM59.8085 12.802C59.9236 11.1041 60.9979 10.2146 62.4559 10.2146C64.1058 10.2146 64.7964 11.5083 64.7964 12.802L59.8085 12.802Z" fill="#8134FF"/>
                <path d="M78.571 10.3357C79.1849 10.3357 79.7221 9.81016 79.7221 9.12288C79.7221 8.47603 79.2233 7.78876 78.571 7.78876L77.4583 7.78876V6.6972C77.4583 5.92907 76.8444 5.28223 76.1154 5.28223C75.3864 5.28223 74.7725 5.92907 74.7725 6.6972V7.78876L74.197 7.78876C73.5831 7.78876 73.0459 8.43561 73.0459 9.12288C73.0459 9.76973 73.5447 10.3357 74.197 10.3357L74.7725 10.3357V16.8042C74.7725 18.9064 76.1538 20.4023 78.4175 20.4023C79.0698 20.4023 79.607 19.8363 79.607 19.149C79.607 18.4617 79.0698 17.8957 78.4175 17.8957C77.9571 17.8957 77.4583 17.5319 77.4583 16.9255V10.3357L78.571 10.3357Z" fill="#8134FF"/>
                <path d="M91.5782 13.0446C91.5782 11.2253 90.8109 7.74854 86.5519 7.74854C83.1371 7.74854 81.1035 10.0125 81.1035 14.0957C81.1035 18.5428 83.2522 20.5238 86.7821 20.5238C89.0843 20.5238 90.3888 19.4726 90.8492 19.0684C91.0795 18.8662 91.2713 18.5024 91.2713 18.0981C91.2713 17.4108 90.7341 16.8448 90.0819 16.8448C89.7365 16.8448 89.5447 16.9661 89.2761 17.1278C88.6622 17.5725 87.8948 17.9364 86.7821 17.9364C85.1323 17.9364 83.8277 17.047 83.8277 15.1468L90.0435 15.1468C91.5782 15.1873 91.5782 14.0149 91.5782 13.0446ZM83.9045 12.802C84.0196 11.1041 85.0939 10.2146 86.5519 10.2146C88.2018 10.2146 88.8924 11.5083 88.8924 12.802L83.9045 12.802Z" fill="#8134FF"/>
                <path d="M96.1441 11.2657C96.1441 10.6188 96.6813 10.255 97.6405 10.255C98.6765 10.255 99.3671 10.6592 99.9043 11.104C100.173 11.3061 100.403 11.4678 100.748 11.4678C101.401 11.4678 101.938 10.9018 101.938 10.1741C101.938 9.76983 101.823 9.60812 101.669 9.36555C100.979 8.43571 99.3671 7.70801 97.6021 7.70801C95.3 7.70801 93.3815 8.88042 93.3815 11.2657C93.3815 15.7936 99.5589 14.6616 99.5589 16.8043C99.5589 17.532 98.8299 17.8554 97.6789 17.8554C96.4127 17.8554 95.6069 17.3703 94.9163 16.7639C94.6477 16.5213 94.3791 16.4404 94.1489 16.4404C93.4966 16.4404 92.9595 17.0064 92.9595 17.6937C92.9595 18.0171 93.0746 18.2597 93.2664 18.5023C93.8803 19.3108 95.5686 20.362 97.7172 20.362C100.326 20.362 102.322 19.1087 102.322 16.6022C102.322 12.3168 96.1441 13.2466 96.1441 11.2657Z" fill="#8134FF"/>
                <path d="M108.844 10.3357C109.458 10.3357 109.995 9.81016 109.995 9.12288C109.995 8.47603 109.497 7.78876 108.844 7.78876L107.693 7.78876V6.6972C107.693 5.92907 107.079 5.28223 106.35 5.28223C105.621 5.28223 105.007 5.92907 105.007 6.6972V7.78876L104.432 7.78876C103.818 7.78876 103.281 8.43561 103.281 9.12288C103.281 9.76973 103.78 10.3357 104.432 10.3357L105.007 10.3357V16.8042C105.007 18.9064 106.389 20.4023 108.652 20.4023C109.305 20.4023 109.842 19.8363 109.842 19.149C109.842 18.4617 109.305 17.8957 108.652 17.8957C108.192 17.8957 107.693 17.5319 107.693 16.9255V10.3357L108.844 10.3357Z" fill="#8134FF"/>
                <path d="M121.697 13.0446C121.697 11.2253 120.93 7.74854 116.671 7.74854C113.256 7.74854 111.223 10.0125 111.223 14.0957C111.223 18.5428 113.371 20.5238 116.901 20.5238C119.203 20.5238 120.508 19.4726 120.968 19.0684C121.199 18.8662 121.39 18.5024 121.39 18.0981C121.39 17.4108 120.853 16.8448 120.201 16.8448C119.856 16.8448 119.664 16.9661 119.395 17.1278C118.781 17.5725 118.014 17.9364 116.901 17.9364C115.251 17.9364 113.947 17.047 113.947 15.1468L120.163 15.1468C121.697 15.1873 121.697 14.0149 121.697 13.0446ZM114.024 12.802C114.139 11.1041 115.213 10.2146 116.671 10.2146C118.321 10.2146 119.012 11.5083 119.012 12.802L114.024 12.802Z" fill="#8134FF"/>
                <path d="M129.026 7.78882C127.952 7.78882 126.801 8.43567 126.302 9.48679V9.36551C126.302 8.55695 125.688 7.95053 124.959 7.95053C124.192 7.95053 123.616 8.59738 123.616 9.36551L123.616 18.9874C123.616 19.7959 124.23 20.4023 124.959 20.4023C125.727 20.4023 126.302 19.7555 126.302 18.9874V13.4487C126.302 11.6699 127.3 10.4975 129.026 10.4975C129.717 10.4975 130.292 9.89107 130.292 9.16337C130.292 8.39524 129.717 7.78882 129.026 7.78882Z" fill="#8134FF"/>
                <path d="M133.784 11.2657C133.784 10.6188 134.321 10.255 135.281 10.255C136.317 10.255 137.007 10.6592 137.544 11.104C137.813 11.3061 138.043 11.4678 138.389 11.4678C139.041 11.4678 139.616 10.9018 139.616 10.1741C139.616 9.76983 139.501 9.60812 139.348 9.36555C138.657 8.43571 137.046 7.70801 135.281 7.70801C132.978 7.70801 131.06 8.88042 131.06 11.2657C131.098 15.834 137.237 14.7425 137.237 16.8851C137.237 17.6129 136.508 17.9363 135.357 17.9363C134.091 17.9363 133.285 17.4511 132.595 16.8447C132.326 16.6022 132.058 16.5213 131.827 16.5213C131.175 16.5213 130.6 17.0873 130.6 17.7746C130.6 18.098 130.715 18.3406 130.907 18.5831C131.52 19.3917 133.209 20.4428 135.357 20.4428C137.966 20.4428 139.962 19.1895 139.962 16.683C140 12.3168 133.784 13.2466 133.784 11.2657Z" fill="#8134FF"/>
                <path d="M23.7414 0.511951C23.0891 0.148099 22.2833 0.350239 21.938 1.03751L13.5736 16.4001L11.9621 13.651L18.0628 2.41206C18.4081 1.72479 18.2162 0.875802 17.564 0.511951C16.9117 0.148099 16.1059 0.350239 15.7606 1.03751L10.3889 10.9828L8.93092 8.47625C8.54723 7.82941 7.74148 7.62727 7.08921 8.03155C6.4753 8.43583 6.28346 9.28481 6.66715 9.97209L8.89255 13.7723L7.43453 16.481L2.71514 8.43583C2.33145 7.78898 1.5257 7.58684 0.873423 7.99112C0.259519 8.3954 0.0676737 9.24438 0.451364 9.93166L6.32183 19.8769C6.55204 20.2812 6.9741 20.5238 7.43453 20.5238C7.43453 20.5238 7.43453 20.5238 7.4729 20.5238C7.93333 20.5238 8.35539 20.2408 8.5856 19.8365L10.4273 16.4001L12.4609 19.8769C12.6911 20.2812 13.1131 20.5238 13.5736 20.5238C13.5736 20.5238 13.5736 20.5238 13.6119 20.5238C14.0724 20.5238 14.4944 20.2408 14.7246 19.8365L24.2402 2.41206C24.5855 1.72479 24.3553 0.875802 23.7414 0.511951Z" fill="#8134FF"/>
                </g>
                <defs>
                <clipPath id="clip0_3517_780">
                <rect width="140" height="20.5977" fill="white" transform="translate(0 0.201172)"/>
                </clipPath>
                </defs>
            </svg>
            <h1 v-if="!showFinishedPage">{{ data.title }}</h1>
            <h2 v-html="data.text" v-if="!showFinishedPage && showTextAsHtml"></h2>
            <h2 :class="{'withoutHtml':!showTextAsHtml}" v-if="!showFinishedPage && !showTextAsHtml">{{ data.text }}</h2>
            <div class="ModernWelcomePage--Separator" v-if="!showFinishedPage"></div>
            <CheckBox v-if="!showFinishedPage" :disabled="disableActions" :text="$t('suite_watfocus_step3_welcome_preview_conditions')" v-model="acceptLegalText" v-on:text-clicked="openLegalText()"/>
            <button v-if="!showFinishedPage" :disabled="disableActions || !acceptLegalText" v-on:click="$emit('accept')">
                {{ $t('watfocus_execution_preview_continue') }}
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.125 8.29883H11.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.5 3.92383L11.875 8.29883L7.5 12.6738" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            <h1 v-if="showFinishedPage && publicFinalTitle.length > 3">{{ publicFinalTitle }}</h1>
            <h1 v-if="showFinishedPage && publicFinalTitle.length < 3">{{ $t('wat_focus_advert_info_completed_title') }}</h1>
            <p v-if="showFinishedPage && publicFinalTitle.length < 3">{{  $t('wat_focus_advert_info_completed')  }}</p>
            <p v-if="showFinishedPage">{{ publicFinalText }}</p>
        </div>
        <div class="SVG_items">
            <svg class="svg_red_1" width="246" height="410" viewBox="0 0 246 410" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-143.108 718.157C-233.56 700.204 -309.311 640.96 -359.408 563.722C-406.756 490.724 -410.77 403.252 -403.689 316.405C-395.813 219.812 -392.781 111.479 -317.527 50.0937C-240.141 -13.0294 -130.096 -4.1928 -31.9304 13.163C63.5818 30.0497 162.487 62.941 215.052 144.254C265.71 222.62 242.387 321.371 226.899 413.556C211.643 504.363 199.531 601.901 128.315 660.528C54.0429 721.671 -48.9207 736.852 -143.108 718.157Z" fill="url(#paint0_linear_3517_823)"/>
                <defs>
                <linearGradient id="paint0_linear_3517_823" x1="211.712" y1="139.212" x2="-369.712" y2="549.668" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>
            <svg class="svg_red_2" width="214" height="407" viewBox="0 0 214 407" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M389.108 -311.277C479.56 -293.324 555.311 -234.08 605.408 -156.842C652.756 -83.8438 656.77 3.62802 649.689 90.4748C641.813 187.068 638.781 295.401 563.527 356.786C486.141 419.909 376.096 411.073 277.93 393.717C182.418 376.83 83.5125 343.939 30.9484 262.625C-19.71 184.26 3.61313 85.5085 19.1007 -6.67643C34.3567 -97.4833 46.4693 -195.021 117.685 -253.648C191.957 -314.791 294.921 -329.972 389.108 -311.277Z" fill="url(#paint0_linear_3517_946)"/>
                <defs>
                <linearGradient id="paint0_linear_3517_946" x1="34.2877" y1="267.668" x2="615.712" y2="-142.788" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF1564"/>
                <stop offset="1" stop-color="#FD7328"/>
                </linearGradient>
                </defs>
            </svg>

            <svg class="svg_elipse_1" width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g style="mix-blend-mode:overlay">
                <circle cx="61.7533" cy="62.1998" r="61.7533" fill="url(#paint0_linear_3517_1070)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_3517_1070" x1="61.7533" y1="0.446533" x2="61.7533" y2="123.953" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                </defs>
            </svg>
            <svg class="svg_elipse_2" width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g style="mix-blend-mode:overlay">
                <circle cx="26.0014" cy="26.0014" r="26.0014" fill="url(#paint0_linear_3517_1072)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_3517_1072" x1="26.0014" y1="0" x2="26.0014" y2="52.0028" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                </defs>
            </svg>
            <svg class="svg_elipse_3" width="165" height="165" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g style="mix-blend-mode:overlay">
                <circle cx="82.6624" cy="82.3377" r="82.3377" fill="url(#paint0_linear_3517_1069)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_3517_1069" x1="82.6624" y1="0" x2="82.6624" y2="164.675" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                </defs>
            </svg>
            <svg class="svg_elipse_4" width="77" height="77" viewBox="0 0 77 77" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g style="mix-blend-mode:overlay">
                <circle cx="38.2798" cy="38.2798" r="38.2798" fill="url(#paint0_linear_3517_1071)"/>
                </g>
                <defs>
                <linearGradient id="paint0_linear_3517_1071" x1="38.2798" y1="0" x2="38.2798" y2="76.5596" gradientUnits="userSpaceOnUse">
                <stop stop-color="white"/>
                <stop offset="1" stop-color="white" stop-opacity="0"/>
                </linearGradient>
                </defs>
            </svg>
            <svg :class="'svg_pattern_'+i" v-for="i in 2"  width="160" height="32" viewBox="0 0 160 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_3517_947)">
                <circle cx="154.186" cy="9.25364" r="4.82444" transform="rotate(90 154.186 9.25364)" fill="white"/>
                <circle cx="154.186" cy="26.9432" r="4.82444" transform="rotate(90 154.186 26.9432)" fill="white"/>
                <circle cx="136.497" cy="9.25364" r="4.82444" transform="rotate(90 136.497 9.25364)" fill="white"/>
                <circle cx="136.497" cy="26.9432" r="4.82444" transform="rotate(90 136.497 26.9432)" fill="white"/>
                <circle cx="118.807" cy="9.25364" r="4.82444" transform="rotate(90 118.807 9.25364)" fill="white"/>
                <circle cx="118.807" cy="26.9432" r="4.82444" transform="rotate(90 118.807 26.9432)" fill="white"/>
                <circle cx="101.117" cy="9.25364" r="4.82444" transform="rotate(90 101.117 9.25364)" fill="white"/>
                <circle cx="101.117" cy="26.9432" r="4.82444" transform="rotate(90 101.117 26.9432)" fill="white"/>
                <circle cx="83.428" cy="9.25364" r="4.82444" transform="rotate(90 83.428 9.25364)" fill="white"/>
                <circle cx="83.428" cy="26.9432" r="4.82444" transform="rotate(90 83.428 26.9432)" fill="white"/>
                <circle cx="65.7381" cy="9.25364" r="4.82444" transform="rotate(90 65.7381 9.25364)" fill="white"/>
                <circle cx="65.7381" cy="26.9432" r="4.82444" transform="rotate(90 65.7381 26.9432)" fill="white"/>
                <circle cx="48.0486" cy="9.25364" r="4.82444" transform="rotate(90 48.0486 9.25364)" fill="white"/>
                <circle cx="48.0486" cy="26.9432" r="4.82444" transform="rotate(90 48.0486 26.9432)" fill="white"/>
                <circle cx="30.3587" cy="9.25364" r="4.82444" transform="rotate(90 30.3587 9.25364)" fill="white"/>
                <circle cx="30.3587" cy="26.9432" r="4.82444" transform="rotate(90 30.3587 26.9432)" fill="white"/>
                <circle cx="12.6692" cy="9.25364" r="4.82444" transform="rotate(90 12.6692 9.25364)" fill="white"/>
                <circle cx="12.6692" cy="26.9432" r="4.82444" transform="rotate(90 12.6692 26.9432)" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_3517_947">
                <rect width="45.2658" height="159.011" fill="white" transform="translate(159.011 -13.5402) rotate(90)"/>
                </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</template>
<script>   
    import CheckBox from '../WatMonitor/CheckBox.vue'
    import Swal from "sweetalert2";
    import i18n from '@/i18n';
   export default {
    name: 'ModernWelcomePage',
    components:{
        CheckBox
    },
    props: {
        data: {
        type: Object,
        default: ()=>{},
        },
        disableActions:{
            type:Boolean,
            default: false
        },
        showFinishedPage:{
            type: Boolean,
            default: false
        },
        publicFinalText:{
            type: String,
            default: ""
        },
        publicFinalTitle:{
            type: String,
            default: ""
        },
        showTextAsHtml:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
            acceptLegalText: false
        }
    },
    methods:{
        openLegalText(){
            Swal.fire({
                title:"",
                html:this.data.legalText,
                iconHtml: "!",
                icon:"warning",
                showCancelButton: false,
                showCloseButton: true
            });    
        }
    }
   }
</script>
<style lang="scss" scoped>
.ModernWelcomePage{
    background: linear-gradient(180deg, #3E216C 0%, #895AD2 100%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    z-index: 1;
    &--Content{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        width: 500px;
        background: white;
        padding: 56px 32px 56px 32px;
        border-radius: 12px;
        word-break: break-word;
        z-index: 9;
        svg{
            position: relative;
        }
        button{
            width: 100%;
            height: 53px;
            gap: 8px;
            border-radius: 9999px;
            background: #96C800;
            color: white;
            font-family: Lato;
            font-size: 16px;
            font-weight: 900;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
    h1{
        font-family: Bariol;
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        color: #2D0077;
    }
    h2{
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }

    .withoutHtml{
        text-align: left !important;
        white-space: pre-wrap; 
        overflow-y: auto;
        height: 230px;
    }

    &--Separator{
        border-bottom: 2px dashed #C8C8C8;
        width: 100%;
    }
}
@media screen and (max-width: 370px) {
    .ModernWelcomePage{
        overflow-y: visible;
        &--Content{
            gap: 20px;
        }
    }
}
svg{
    position: absolute;
}
.svg_red_1{
    left: -74px;
    bottom: -59px;
}
.svg_red_2{
    right: -74px;
    top: -59px;
}
.svg_elipse_1{
    left: 70px;
    bottom: 80px;
}
.svg_elipse_2{
    right: 70px;
    bottom: 80px;
}
.svg_elipse_3{
    right: 70px;
    top: 80px;
}
.svg_elipse_4{
    left: 70px;
    top: 80px;
}
.svg_pattern_1{
    top: 0;
    right: 50px;
}
.svg_pattern_2{
    bottom: 0;
    left: 50px;
}
</style>
   